<template>
  <div class="home">
    <b-row>
      <b-col v-for="(p, i) in $store.state.game.players" :key="p.id" class="playerCol" :style="{ backgroundColor: p.color }">
        <div style="align-self: center">
          <p>
            Player {{i + 1}}
            <span v-if="p.name">({{p.name}})</span>
            <br>
            Key: {{p.key.toUpperCase()}}
          </p>
          <b-button @click="changeName(i)">change name</b-button>
          <b-button @click="removePlayer(i)">remove player</b-button>
        </div>
      </b-col>
      <b-col class="playerCol" style="background-color: #bbbbbb;">
        <div class="playerRow" @click="$router.push('/gametype')" style="flex-grow: 3;">
          <p>
            Add Player by pressing any Key
            <br>
            Press ENTER or click here to continue.
            <br>
            Press BACKSPACE to delete the last player or click the one you want to remove
          </p>
        </div>
        <div class="playerRow" style="background-color: #999999;" v-if="$store.state.game.iPadMode" @click="randomPlayer">
          <p>
            Click here to add a random player
          </p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import crypto from 'crypto';
import randomColor from 'randomcolor'

export default {
  name: 'SelectDevices',
  data() {
    return {
      joystickEvent: null,
    };
  },
  components: {
  },
  mounted() {
  },
  created() {
    window.addEventListener('keydown', this.handleKeypress);
    this.$ws.$on("message", this.handleWebsocket);
    this.joystickEvent = window.joypad.on('button_press', this.handleGamepad);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeypress);
    this.$ws.$off("message", this.handleWebsocket);
    this.joystickEvent?.unsubscribe();
  },
  methods: {
    handleKeypress(e) {
      console.log(e);
      if (e.key === 'Enter') return this.$router.push('/gametype');
      if (e.key === 'Backspace') return this.$store.commit('game/REMOVE_PLAYER');
      this.$store.commit('game/ADD_PLAYER', {
        index: this.$store.state.game.players.length + 1,
        key: e.key,
        id: crypto.randomBytes(8).toString('hex'),
        color: randomColor({ luminosity: 'bright' }),
        name: null,
      })
      /*
      this.$store.state.game.players.push({
        key: e.key,
        id: crypto.randomBytes(8).toString(),
        color: randomColor({ luminosity: 'bright' }),
      });
      */
    },
    handleWebsocket(msg) {
      console.log(msg);
      const content = msg.split(':');
      if (content[0] === 'button-press')
      this.$store.commit('game/ADD_PLAYER', {
        index: this.$store.state.game.players.length + 1,
        key: content[1],
        id: crypto.randomBytes(8).toString('hex'),
        color: randomColor({ luminosity: 'bright' }),
      })
    },
    handleGamepad(e) {
      if (e.detail.buttonName === 'button_0') return this.$router.push('/gametype');
      this.$store.commit('game/ADD_PLAYER', {
        index: this.$store.state.game.players.length + 1,
        key: e.detail.buttonName,
        gamepad: e.detail.gamepad.id,
        id: crypto.randomBytes(8).toString('hex'),
        color: randomColor({ luminosity: 'bright' }),
      })
    },
    removePlayer(i) {
      this.$store.commit('game/REMOVE_PLAYER_INDEX', i);
    },
    randomPlayer() {
      const used = this.$store.state.game.players.filter((p) => p.key).map((p) => p.key);
      const keys = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z' ].filter((e) => !used.includes(e));
      console.log(used, keys);
      const rand = Math.floor(Math.random() * keys.length);
      this.$store.commit('game/ADD_PLAYER', {
        index: this.$store.state.game.players.length + 1,
        key: keys[rand],
        id: crypto.randomBytes(8).toString('hex'),
        color: randomColor({ luminosity: 'bright' }),
        name: null,
      })
    },
    changeName(i) {
      const name = prompt('enter name');
      this.$store.commit('game/CHANGE_PLAYER', { i, name })
    }
  }
}
</script>

<style scoped>
.playerCol {
  height: 100vh;
  display:flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.playerRow {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>